

/*==============================================
        satisfaction Slider Start Here
===============================================*/

var swiper = new Swiper("#swiper-satisfaction", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop:true,
    spaceBetween: 200,
    pagination: {
      el: ".swiper-pagination",
      type: 'bullets',
      clickable: 'true'
    },
  });
 
/*==============================================
        satisfaction Slider End Here
===============================================*/


  var swiper = new Swiper("#categories2", {
    spaceBetween: 24,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper("#categories1", {
    spaceBetween: 24,
    navigation: {
     
    },
    thumbs: {
      swiper: swiper,
    },
  });



  var swiper = new Swiper("#categories4", {
    spaceBetween: 24,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper("#categories3", {
    spaceBetween: 24,
    navigation: {
     
    },
    thumbs: {
      swiper: swiper,
    },
  });



  var swiper = new Swiper("#categories6", {
    spaceBetween: 24,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper("#categories5", {
    spaceBetween: 24,
    navigation: {
     
    },
    thumbs: {
      swiper: swiper,
    },
  });


  var swiper = new Swiper("#categories8", {
    spaceBetween: 24,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper("#categories7", {
    spaceBetween: 24,
    navigation: {
     
    },
    thumbs: {
      swiper: swiper,
    },
  });


/*=======================================
        Catalogue Js Start Here
=======================================*/

  var swiper = new Swiper("#swiper1", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });



  var swiper = new Swiper("#swiper2", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });



  var swiper = new Swiper("#swiper3", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });



  var swiper = new Swiper("#swiper4", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });



  var swiper = new Swiper("#swiper5", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });



  var swiper = new Swiper("#swiper6", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });

  var swiper = new Swiper("#swiper7", {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: false,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true',
    },
  });


/*=======================================
        Catalogue Js End Here
=======================================*/


/*=======================================
       About Swiper Start Here
=======================================*/

var swiper = new Swiper("#swiperone", {
  spaceBetween: 72,
  loop: true,
  slidesPerView: 2,
  centeredSlides: false,
});

/*=======================================
       About Swiper End Here
=======================================*/


    var swiper = new Swiper("#categories12", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories11", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories14", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories13", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories16", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories15", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories18", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories17", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories20", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories19", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories22", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories21", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories24", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories23", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories26", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories25", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories28", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories27", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories30", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories29", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories32", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories31", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories34", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories33", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories36", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories35", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories38", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories37", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories40", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories39", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories42", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories41", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories44", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories43", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories46", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories45", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });

    var swiper = new Swiper("#categories48", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories47", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });
    
    var swiper = new Swiper("#categories50", {
      spaceBetween: 24,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper("#categories49", {
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
    });


// AOS Animations JS Start Here

AOS.init({
  once: true,
});
AOS.refresh();

// AOS Animations JS End here

//Pre Loader Start Here

$(window).on('load', function () {
  $('.loader-main').fadeOut();
}); 

//Pre Loader End Here 

//Loader Js

window.addEventListener("load", function () {
	const Loader = document.querySelector(".loader-main");
	Loader.classList.add("hidden");
});

//Loader Js